import './App.css';

function App() {
  return (
    <div className="App">
      Hello Nikhil Bilgi

      <br/> 

      Welcome to your portfolio site
    </div>
  );
}

export default App;